import NetworkPicker from "views/NetworkPicker.js";

export const Header = () => (
  <div className="so-back" data-testid="page-header">
    <div className="so-chunk">
      <div className="so-siteHeader LaboratoryChrome__header">
        <span className="so-logo">
          <a href="https://www.finapptech.net" className="so-logo__main">
            <img src="https://www.finapptech.net/images/FinAppTech.png" width="180" height="60"/>
          </a>
          <span className="so-logo__separator"> </span>
          <a href="#txsigner" className="so-logo__subSite">
            Transaction Signer
          </a>
        </span>

        <NetworkPicker />
      </div>
    </div>
  </div>
);
