export const Introduction = () => {
  return (
    <div className="Introduction" data-testid="page-introduction">
      <div className="so-back">
        <div className="so-chunk">
          <div className="Introduction__container">
            <h2>FinAppTech Laboratory</h2>
            <p className="Introduction__lead">
              The FinAppTech Laboratory allows you to sign a{" "}<a href="#txsigner">transaction</a>{" "}
              and submit it to the stellar network.
            </p>

          </div>
        </div>
      </div>
    </div>
  );
};
